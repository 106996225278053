.toast-notification {
    position: fixed !important;
    bottom: 0% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999 !important;
    transition: ease-in 0.1;
    width: 100%;
    min-width: 250px;
    max-width: 350px;
}

.toast-notification i {
    font-size: 20px;
}

.create-event-modal textarea {
    resize: none;
}

.create-event-modal label {
    color: #000;
    font-weight: bolder;
}

.gmap-wrapper {
    object-fit: contain;
    position: relative;
    width: 100%;
    background-color: aqua;
}

.truncate {
    min-width: 200px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: hidden;
    resize: horizontal
}

.chat {
    bottom: 20px;
    right: 40px;
    width: 100%;
    max-width: 350px;
    height: 500px;
}

.deleted-user {
    background-color: #900C3F;
    opacity: 0.4;
    transition: 0.4s;
}

.chat .chat-body {
    height: 400px;
    overflow-y: scroll;
}

.chat .chat-body::-webkit-scrollbar {
    display: none;
}

.chat .chat-footer i {
    cursor: pointer;
}

.fa-times {
    cursor: pointer !important;
}

i.clip-board {
    right: 25px;
    top: 0px;
    cursor: pointer !important;
}

/* .chat .chat-footer {
    bottom: 20px;
    right: 8%;
    z-index: 99;
} */

.user-sended-chat {
    width: 80%;
    min-height: 20px;
    float: left;
    position: relative;
}

.admin-sended-chat {
    width: 80%;
    min-height: 20px;
    float: right;
    position: relative;
}

.user-sended-chat p, .admin-sended-chat p {
    min-height: 10px;
    height: auto;
    max-height: 100%;
    border-radius: 10px 10px 0px 10px;
    padding: 5px 10px;
}

.user-sended-chat::before {
    color: #fff;
}

.admin-sended-chat::before {
    color: #5e72e4;
}

.user-sended-chat::before, .admin-sended-chat::before {
    content: '\f111';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 5px;
    right: -2%;
    bottom: 10px;
    position: absolute
}

.admin-sended-chat .username, .user-sended-chat .username {
    font-style: italic;
    font-size: 12px;
}

.event-images {
    object-fit: contain;
}

.event-images img {
    border-radius: 10px;
    max-width: 190px;
    display: block;
    margin: 0 auto;
}

.all-group-members {
    height: 80px;
    overflow-y: scroll;
}

.all-group-members div:hover {
    background-color: #5e72e4;
}

.toolbarClassName {
    padding: 20px;
    background-color: #1c345d;
    color: #4d7bca;
    border: 0px;
}

.editorClassName {
    padding: 20px;
    height: 250px;
}

.all-group-members::-webkit-scrollbar {
    display: none;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #5e72e4 !important;
    border-radius: 50%;
    padding: 20px;
    background-size: 18px;
}

.detail-dialog label+span {
    margin-left: 8px;
}

.detail-dialog label {
    font-weight: 600;
    color: #5e72e4;
}

.avatar-group {
    display: flex;
}

.visually-hidden {
    display: none !important;
}

.badge-danger {
    color: #fff;
    background-color: #d50000;
}

.badge-success {
    color: #fff;
    background-color: #1aae6f;
}

.html-viewer {
    height: 350px;
    resize: none;
    overflow-y: scroll;
}

.html-viewer::-webkit-scrollbar {
    width: 5px;
}

.html-viewer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

.html-viewer::-webkit-scrollbar-thumb {
    background: #5e72e4;
}

.html-viewer::-webkit-scrollbar-thumb:hover {
    background: #2a3ae2;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.toolbarClassName .rdw-option-wrapper {
    border: 1px solid #ffffff;
    background: #ffffff;
    color: #fff;
    border-radius: 4px;
    padding: 15px 8px;
}

.toolbarClassName .rdw-colorpicker-modal-options .rdw-option-wrapper {
    padding: 0px 0px;
}

.workout-details span {
    color: #000;
    font-size: 14px;
}

.ltg-gmap-wrapper {
    position: relative;
    height: 380px;
}

.upload-file-contianer {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 3px dashed #1aae6f;
}

.images i.fas.fa-times {
    font-size: 20px;
    color: #d50000;

}